<style lang="scss" scoped>
.page-form {
  margin: 0 !important;
  padding: 15px;
  .el-col {
    height: 100%;
    overflow: auto;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
    h1 {
      font-size: 18px;
      color: #333;
      margin-bottom: 24px;
    }
  }
}
.el-form-item {
  margin-bottom: 10px !important;
}
.time-line {
  box-sizing: border-box;
  padding: 0 0 20px 20px;
  .el-timeline {
    padding-left: 36px;
    h4 {
      font-weight: 500;
      color: #333;
      line-height: 28px;
    }
    p {
      color: #666;
      font-weight: lighter;
      line-height: 24px;
    }
  }
}
::v-deep .el-card__body {
  padding: 6px 20px 10px;
}
</style>

<template>
  <!-- 用户新增/编辑 -->
  <section class="page-child-contianer">
    <el-row class="page-form" :gutter="40">
      <el-col :span="10">
        <h1>任务详情</h1>
        <el-form :model="formData" ref="formData" label-width="130px">
          <el-form-item label="任务发布时间:" prop="create_time_format">
            <!-- <el-input v-model="formData.create_time_format" /> -->
            {{ formData.create_time_format }}
          </el-form-item>

          <el-form-item label="发布人所属单位:" prop="company_name">
            <!-- <el-input v-model="formData.company_name" /> -->
            {{ formData.company_name }}
          </el-form-item>

          <el-form-item label="发布人所属部门:" prop="branch_name">
            <!-- <el-input v-model="formData.branch_name" /> -->
            {{ formData.branch_name }}
          </el-form-item>
          <el-form-item label="发布人职称:" prop="front_role_name">
            <!-- <el-input v-model="formData.front_role_name" /> -->
            {{ formData.front_role_name }}
          </el-form-item>
          <el-form-item label="发布人职级:" prop="position_rank_name">
            <!-- <el-input v-model="formData.position_rank_name" /> -->
            {{ formData.position_rank_name }}
          </el-form-item>
          <el-form-item label="发布人姓名:" prop="issue_user_name">
            <!-- <el-input v-model="formData.issue_user_name" /> -->
            {{ formData.issue_user_name }}
          </el-form-item>
          <el-form-item label="任务执行-范围:" prop="do_range_name" v-if="formData.do_range_name">
            <!-- <el-input v-model="formData.do_range_name" /> -->
            {{ formData.do_range_name }}
          </el-form-item>
          <el-form-item
            label="任务执行-部门:"
            v-if="formData.do_branch_range && formData.do_branch_range.length"
          >
            <div>
              <div
                v-for="item in formData.do_branch_range"
                :key="item.id - item.company_id"
              >
                {{ item.company_name }}-{{ item.name }}
              </div>
            </div>
          </el-form-item>

          <el-form-item
            label="任务执行-人员:"
            v-if="formData.do_user_range && formData.do_user_range.length"
          >
            <div>
              <div v-for="item in formData.do_user_range" :key="item.id">
                {{ item.branch_name }}-{{ item.front_role_name }}-{{
                  item.name
                }}【{{ item.mobile }}】
              </div>
            </div>
          </el-form-item>

          <el-form-item label="任务标题:" prop="name">
            <!-- <el-input v-model="formData.name" /> -->
            {{ formData.name }}
          </el-form-item>
          <el-form-item label="任务描述:" prop="content">
            <!-- <el-input v-model="formData.content" /> -->
            {{ formData.content }}
          </el-form-item>
          <el-form-item label="要求完成的时间:" prop="finish_time_format">
            <!-- <el-input v-model="formData.finish_time_format" /> -->
            {{ formData.finish_time_format }}
          </el-form-item>
          <el-form-item label="任务状态:" prop="status_name">
            <!-- <el-input v-model="formData.status_name" /> -->
            {{ formData.status_name }}
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="7" v-if="formData.audit_list.length">
        <div class="time-line">
          <h1>任务动态</h1>
          <el-timeline reverse>
            <el-timeline-item
              v-for="item in formData.audit_list"
              :key="item.id"
              :timestamp="item.create_time_text"
              placement="top"
            >
              <el-card>
                <h4>{{ item.do_result_name }}</h4>
                <p>处理人员：{{ item.issue_user_name }}</p>
                <p v-if="item.do_result_name">
                  处理结果：{{ item.do_result_name }}
                </p>
                <p v-if="item.desc">备注说明：{{ item.desc }}</p>
                <div v-if="item.annex_list.length">
                  <p>完成凭证：</p>
                  <el-row :gutter="10">
                    <el-col
                      :span="6"
                      v-for="img in item.annex_list"
                      :key="img.id"
                    >
                      <a target="_blank" :href="img.path_source">
                        <img :src="img.path_source" style="width: 100%" />
                      </a>
                    </el-col>
                  </el-row>
                </div>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </el-col>
    </el-row>
  </section>
</template>

<script>
import { mapState } from "vuex";
import { taskApplyDetail } from "@api/taskApply";
import { formatTime } from "@utils";
export default {
  name: "taskApplyForm",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {},
  data() {
    return {
      id: null, //修改用户id
      imageUrl: "", //图片上传成功回显链接
      formData: {
        checkList: [],
        status: 2,
        position_rank: 3,
      }, //表单存储数据
    };
  },
  computed: {},
  async created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getDetail();
    }
  },
  mounted() {},
  methods: {
    /** 修改前获取详情信息 */
    async getDetail() {
      /**
       * 请求接口获取详情数据
       */
      let res = await taskApplyDetail(this.id);
      res.data.status = Number(res.data.status);
      res.data.password = "";
      if (res.data.audit_list.length) {
        res.data.audit_list.map(
          (item) =>
            (item.create_time_text = formatTime(item.create_time * 1000))
        );
      }
      this.formData = {
        ...this.formData,
        ...res.data,
      };
      this.imageUrl = res.data.thumb_path_source;

      //请求返回成功后打开弹窗
      this.formDialogVisible = true;
    },
    /** 取消用户编辑 */
    handleCancle() {
      this.$refs["formData"].clearValidate();
      this.$router.go(-1);
    },

    /** 上传前处理 */
    uploadBefore(file) {
      const isImg =
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png" ||
        file.type == "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImg) {
        this.$message.error("上传图片只能是 .jpg,.jpeg,.png,.gif 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isImg && isLt2M;
    },
    /** 上传成功 */
    uploadSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData = {
        ...this.formData,
        thumb: res.data.list[0].id,
      };
    },
    /** 上传失败 */
    uploadError(err, file) {
      this.$message.warning("上传失败");
    },
  },
};
</script>
